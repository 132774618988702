import React from 'react';

import Loader from 'react-loader-spinner';

export default function LoaderScreen({ show, texto, tipoLoader, color, height, width }) {

    const paddingModal = texto ? { padding: '40px 40px 20px 40px' } : { padding: 40 };
    return (
        <>
            {show ? (
                <div style={styles.backdrop}>
                    <div style={{ ...styles.modal, ...paddingModal }}>
                        <Loader
                            type={tipoLoader ? tipoLoader : "TailSpin"}
                            color={color ? color : "#3e5074"}
                            height={height ? height : 50}
                            width={width ? width : 50}
                        />
                        {texto ? (
                            <h3 style={styles.text}>{texto}</h3>
                        ) : null}
                    </div>
                </div>
            ) : null
            }
        </>
    );
}


const styles = {
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)', width: '100%', height: '100%', display: 'flex', position: 'fixed',
        justifyContent: 'center', alignItems: 'center', zIndex: 999
    },
    modal: { backgroundColor: '#FFF', borderRadius: 5, textAlign: 'center' },
    text: { color: '#3E5074', paddingTop: 30 }
};