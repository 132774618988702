import React, { useState, useEffect } from 'react';

import logoExossWallet from '../resources/img/logo-exosswallet-preto.svg';
import iconDashboard from '../resources/img/menu-dashboard.svg';
import iconContaDigital from '../resources/img/menu-conta-digital.svg';
import iconTransferencia from '../resources/img/menu-transferencia.svg';
import iconAdquirencia from '../resources/img/menu-adquirencia.svg';

import { Link } from 'react-router-dom';

export default function SideMenu({ menuAtivo }) {

    const [nomeUsuario, setNomeUsuario] = useState('');
    const [user, setUser] = useState({ data: { produtos: [] } });
    const [menus] = useState([
        { nome: 'Dashboard', url: '/home', icone: iconDashboard, id: 0, valida: false },
        { nome: 'Contas Digitais', url: '/contas-digitais', icone: iconContaDigital, id: 1, valida: true },
        { nome: 'Transferência', url: '/transferencia', icone: iconTransferencia, id: 7, valida: true },
        { nome: 'Adquirência (cartão)', url: '/adquirencia', icone: iconAdquirencia, id: 2, valida: true }
    ]);

    useEffect(() => {
        const userData = sessionStorage.getItem('userData');
        if (userData) {
            const user = JSON.parse(userData);
            setUser(user);
            const nome = user.data.vendedor.apelidofantasia.split(" ");
            setNomeUsuario(nome[0]);
        } else {
            setNomeUsuario('Usuário');
        }
    }, []);

    return (
        <>
            <div className="sideMenuBar">
                <div className="profile">
                    <img src={logoExossWallet} alt="Logo Exoss Card" />
                    <p>Olá {nomeUsuario}.</p>
                </div>
                <div className="navigation">
                    <ul>
                        {menus.map((item, i) => {
                            const ultimoItem = (i + 1) === menus.length ? "noBorder" : "";
                            if(!item.valida){
                                return (
                                    <li key={i}>
                                        <Link to={item.url} className={menuAtivo === (i + 1) ? `active ${ultimoItem}` : ultimoItem}
                                            style={menuAtivo === (i + 2) ? { borderBottom: 'none' } : {}}>
                                            <img src={item.icone} alt={`Ícone de ${item.nome}`} /> {item.nome}
                                        </Link>
                                    </li>
                                )
                            }else{
                                if(user.data.produtos.indexOf(item.id) !== -1){
                                    return (
                                        <li key={i}>
                                            <Link to={item.url} className={menuAtivo === (i + 1) ? `active ${ultimoItem}` : ultimoItem}
                                                style={menuAtivo === (i + 2) ? { borderBottom: 'none' } : {}}>
                                                <img src={item.icone} alt={`Ícone de ${item.nome}`} /> {item.nome}
                                            </Link>
                                        </li>
                                    )
                                }
                            }
                        })}
                    </ul>
                </div>
            </div>
        </>
    )
}