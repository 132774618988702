import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import SideMenu from '../../../Components/SideMenu';
import Header from '../../../Components/Header';
import Redirect from '../../../Components/RedirectScreen';
import Loading from '../../../Components/LoaderScreen';
import Alert from '../../../Components/Alert';
import Table from '../../../Components/Table';
import Input from '../../../Components/Input';
import ApiService from '../../../Utils/services';
import Funcoes from '../../../Utils/functions';
import Paginacao from '../../../Components/Paginacao';
import { dataBrMask } from '../../../Utils/mask';

export default function DigitalAccountDetails(props) {

    const [redirect, setRedirect] = useState(false);
    const [path, setPath] = useState('');
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [alertType, setAlertType] = useState(2);
    const [idConta, setIdConta] = useState('');
    const [user, setUser] = useState({});
    const [nome, setNome] = useState('');
    const [perfil, setPerfil] = useState({});

    useEffect(() => {
        const id = props.location.state.id;
        const userData = JSON.parse(sessionStorage.getItem("userData"));
        setUser(userData);
        setNome(props.location.state.nome);
        setIdConta(id);
        carregaContaDigital(userData.token, id);
    }, []);

    const carregaContaDigital = (token, id) => {
        setLoading(true);
        var url = 'contadigital/contadigital/' + id;
        ApiService.get(url, token)
            .then(result => {
                const response = result.data;
                const dados = [];
                setPerfil(response.data);
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
                const result = ApiService.handleErros(err.response);
                if (result.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    setPath('/');
                }
                setLoading(false);
                setAlertText(result.response);
                setTimeout(() => {
                    setAlert(true);
                }, 300);
            })
    }

    return (
        <>
            <Redirect redirect={redirect} path={path} />
            <Alert show={alert} texto={alertText} tipo={alertType} action={() => { setAlert(false); setRedirect(path ? true : false) }} />
            <Loading show={loading} />
            <SideMenu menuAtivo={2} />

            <div className="body">
                <Header />

                <div className="digitalAccounts mgDefault">
                    <div className="headerBtn">
                        <div>
                            <h1>{nome}</h1>
                            <p className="descricao">Abaixo os dados da conta selecionada.</p>
                        </div>
                    </div>

                    <div className="panel">
                        <div className="panelHeader">
                            <h3>Dados Básicos</h3>
                        </div>
                        <div className="panelBody">
                            <div className="multiInput">
                                <Input type="text" label="Nome/Razão" value={perfil.nomerazao} readonly="true" divStyle={{ flexGrow: 10 }} />
                                <Input type="text" label="Tipo Pessoa" value={perfil.tipopessoa == 0 ? 'Física' : 'Jurídica'} readonly="true" />
                                <Input type="tel" label="CPF/CNPJ" value={perfil.cpfcnpj} />
                            </div>
                            <div className="multiInput">
                                <Input type="tel" label="Data de Nascimento" readonly="true" value={Funcoes.data2Brazilian(perfil.dtnascimento)} />
                                <Input type="text" label="Nome da Mãe" readonly="true" value={perfil.nomemae} divStyle={{ flexGrow: 5 }} />
                                <Input type="email" label="E-mail" readonly="true" value={perfil.email} divStyle={{ flexGrow: 5 }} />
                            </div>
                            <Input type="tel" label="Celular" readonly="true" value={perfil.celular} divStyle={{ maxWidth: 209 }} />
                        </div>
                    </div>

                    <div className="panel">
                        <div className="panelHeader">
                            <h3>Dados da Conta</h3>
                        </div>
                        <div className="panelBody">
                            <div className="multiInput">
                                <Input type="tel" label="Número" value={perfil.numero} readonly="true" />
                                <Input type="text" label="Tipo da Conta" value={perfil.tipo} readonly="true" />
                                <Input type="tel" label="Data de Cadastro" value={Funcoes.data2Brazilian(perfil.dtcadastro)} readonly="true" />
                                <Input type="tel" label="Validada" readonly="true" value={perfil.validada ? 'Sim' : 'Não'} divStyle={{ maxWidth: 118 }} />
                            </div>
                            <div className="multiInput">
                                <Input type="tel" label="Saldo Disponível" readonly="true" value={Funcoes.float2Preco(perfil.saldo_disponivel)} divStyle={{ maxWidth: 176 }} />
                                <Input type="text" label="Saldo Bloqueado" readonly="true" value={Funcoes.float2Preco(perfil.saldo_bloqueado)} divStyle={{ maxWidth: 176 }} />
                            </div>
                        </div>
                    </div>

                    <div className="panel">
                        <div className="panelHeader">
                            <h3>Dados de Endereço</h3>
                        </div>
                        <div className="panelBody">
                            <div className="multiInput">
                                <Input type="text" label="Logradouro (Av..., Rua, Travessa)" readonly="true" value={perfil.endereco} divStyle={{ flexGrow: 5 }} />
                                <Input type="text" label="Número" readonly="true" value={perfil.endereco_numero} divStyle={{ maxWidth: 176 }} />
                                <Input type="text" label="Complemento" readonly="true" value={perfil.endereco_complemento} />
                            </div>
                            <div className="multiInput">
                                <Input type="text" label="Bairro" readonly="true" value={perfil.endereco_bairro} divStyle={{ maxWidth: 275 }} />
                                <Input type="text" label="Cidade" readonly="true" value={perfil.cidade} divStyle={{ maxWidth: 347 }} />
                                <Input type="text" label="UF" readonly="true" value={perfil.uf} divStyle={{ maxWidth: 100 }} />
                            </div>
                        </div>
                    </div>

                    <Link to="/contas-digitais" className="btn btnVoltar">{'<'} Voltar</Link>
                </div>
            </div>
        </>
    )
}
