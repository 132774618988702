import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';

import iconEditar from '../resources/img/icon-editar.svg';
import iconVisualizar from '../resources/img/icon-table-visualizar.svg';
import iconExtrato from '../resources/img/icon-table-extrato.svg';
import iconLixeira from '../resources/img/icon-lixeira.svg';
import iconAprovado from '../resources/img/icon-aprovado.svg';
import iconReprovado from '../resources/img/icon-reprovado.svg';
import iconDesligar from '../resources/img/icon-desligar.svg';
import iconRelogio from '../resources/img/icon-relogio.svg';

export default function Table({ campos, dados, selecionavel, acaoEditar, acaoSaldo, acaoVisualizar, acaoExcluir, acaoSelecionavel }) {

    const [checkboxGeral, setCheckboxGeral] = useState(false);
    const [keys] = useState(['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']);

    const selecionaTodos = () => {
        var checkboxes = document.querySelectorAll('input[type="checkbox"]');
        for (var i = 0; i < checkboxes.length; i++) {
            checkboxes[i].checked = checkboxGeral ? false : true;
        }
        setCheckboxGeral(!checkboxGeral);
    }
    return (
        <>
            <ReactTooltip />
            <table className="table">
                <thead>
                    <tr>
                        {selecionavel ? (
                            <th style={{ width: '5%' }}>
                                <input type="checkbox" className="checkBoxtable" checked={checkboxGeral} onChange={e => {
                                    if (acaoSelecionavel) acaoSelecionavel(e, 1, !checkboxGeral)
                                    selecionaTodos()
                                }} />
                            </th>
                        ) : null}
                        {campos.map((campo, index) => {
                            return (
                                <th key={keys[index]} style={campo.thStyle}>{campo.titulo}</th>
                            )
                        })}
                    </tr>
                </thead>
                <tbody>
                    {dados.map((item, index) => {
                        return (
                            <tr key={index}>
                                {selecionavel ? (
                                    <td><input type="checkbox" className="checkBoxtable" rel={item['id']} value={item['valSelect']}
                                        onChange={e => acaoSelecionavel(e, 0)} /></td>
                                ) : null}
                                {campos.map((campo) => {
                                    if (campo.dado === "tag") {
                                        const val = item[campo.dado];
                                        const splitVal = val.split(' ');
                                        const selectTag = splitVal.length > 1 ? splitVal[1] : splitVal[0];
                                        const arrTags = {
                                            Aprovada: { backgroundColor: '#27AE60', color: '#FFF' },
                                            Cancelada: { backgroundColor: '#888', color: '#FFF' },
                                            Devolvida: { backgroundColor: '#888', color: '#FFF' },
                                            Inativo: { backgroundColor: '#BDBDBD', color: '#FFF' },
                                            Cadastrado: { backgroundColor: '#BDBDBD', color: '#FFF' },
                                            Sim: { backgroundColor: '#27AE60', color: '#FFF' },
                                            Não: { backgroundColor: '#F2994A', color: '#FFF' }
                                        }
                                        return (
                                            <td className="tag" style={{ position: 'relative', ...campo.tdStyle }}>
                                                <span style={arrTags[selectTag]}>{val}</span>
                                            </td>
                                        )
                                    } else if (campo.dado === "acoes") {
                                        if (item[campo.dado]) {
                                            const splitValue = item[campo.dado].split('|');
                                            return (
                                                <td className="acoes" style={campo.tdStyle}>
                                                    {splitValue[0] === "default" ? (
                                                        <img src={iconEditar} alt="Ícone editar" data-tip="Editar" style={{ marginRight: 18, cursor: 'pointer' }}
                                                            onClick={() => acaoEditar(item['id'])} />
                                                    ) : null}
                                                    {splitValue[1] === "extrato" ? (
                                                        <img src={iconExtrato} alt="Ícone extrato" title="Ver Extrato" data-tip="Ver Extrato" style={{ cursor: 'pointer', marginRight: 5 }}
                                                            onClick={() => acaoEditar(item['id'])} />
                                                    ) : null}
                                                    {splitValue[0] === "visualizar" ? (
                                                        <img src={iconVisualizar} alt="Ícone visualizar" title="Ver detalhes" data-tip="Ver detalhes" style={{ cursor: 'pointer', marginRight: 5 }}
                                                            onClick={() => acaoVisualizar(item['id'])} />
                                                    ) : null}
                                                    {splitValue[1] === "excluir" ? (
                                                        <img src={iconLixeira} alt="Ícone exclusão" data-tip="Excluir" style={{ cursor: 'pointer' }}
                                                            onClick={() => acaoExcluir(item['id'], item['nomeExcluir'])} />
                                                    ) : null}
                                                    {splitValue[2] === "saldo" ? (
                                                        <img src={iconRelogio} alt="Ícone relogio" title="Saldo a liberar" data-tip="Saldo a liberar" style={{ cursor: 'pointer', marginRight: 16 }}
                                                            onClick={() => acaoSaldo(item['id'])} />
                                                    ) : null}
                                                    {splitValue[0] === "aprova/reprova" ? (<>
                                                        <button style={{ marginRight: 20 }} title="Aprovar"
                                                            onClick={() => acaoVisualizar(1, item['id'])}>
                                                            <img src={iconAprovado} alt="Ícone de aprovar" />
                                                        </button>
                                                        <button title="Reprovar" onClick={() => acaoVisualizar(2, item['id'])}>
                                                            <img src={iconReprovado} alt="Ícone de reprovar" />
                                                        </button>
                                                    </>) : null}
                                                </td>

                                            )
                                        }
                                    } else {
                                        return (
                                            <td style={campo.tdStyle}>{item[campo.dado]}</td>
                                        )
                                    }
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </>
    );
}