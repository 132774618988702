import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
// import { PrivateRoute } from './Utils/PrivateRoute';

//Screens
import Login from './Screens/Login';
import Dashboard from './Screens/Content/Dashboard/Dashboard';
import CardStatement from './Screens/Content/CardStatement/CardStatement';
import CardStatementDetails from './Screens/Content/CardStatement/CardStatementDetails';
import DigitalAccount from './Screens/Content/DigitalAccount/DigitalAccount';
import DigitalAccountBalance from './Screens/Content/DigitalAccount/DigitalAccountBalance';
import DigitalAccountDetails from './Screens/Content/DigitalAccount/DigitalAccountDetails';
import DigitalAccountStatement from './Screens/Content/DigitalAccount/DigitalAccountStatement';
import Transfer from './Screens/Content/Transfer/Transfer';

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/" exact={true} component={Login} />
      <Route path="/home" component={Dashboard} />
      <Route path="/contas-digitais" exact={true} component={DigitalAccount} />
      <Route path="/contas-digitais/detalhes" exact={true} component={DigitalAccountDetails} />
      <Route path="/contas-digitais/extrato" exact={true} component={DigitalAccountStatement} />
      <Route path="/contas-digitais/saldo_liberar" exact={true} component={DigitalAccountBalance} />
      <Route path="/transferencia" component={Transfer} />
      <Route path="/adquirencia" exact={true} component={CardStatement} />
      <Route path="/adquirencia/detalhes" component={CardStatementDetails} />
      {/* <Route component={NotFound} /> */}
    </Switch>
  </BrowserRouter>,
  document.getElementById('root'));
