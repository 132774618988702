import React from 'react';

import { Redirect } from 'react-router-dom';

export default function RedirectScreen({ redirect, path, props }) {

    return (
        <>
            {redirect ? (
                <>
                    {props ? (
                        <Redirect to={{
                            pathname: `${path}`,
                            state: { ...props }
                        }} />
                    ) : (
                        <Redirect to={`${path}`} />
                    )}
                </>

            ) : null}
        </>
    );

}