import axios from 'axios';

const ExossCardApiVersion = process.env.REACT_APP_WALLET_API_VERSION;
const ExossCardApiUrl = process.env.REACT_APP_WALLET_URL + "/api/";

export default class ApiService {

    static ExossCardApiUrl() { return ExossCardApiUrl; }
    static ExossCardApiVersion() { return ExossCardApiVersion; }

    static getHeaders(token) {
        return {
            "Access-Control-Allow-Origin": "*",
            "Content-type": 'application/json',
            'Accept': 'application/json',
            'Authorization': token ? 'Bearer ' + token : 'Bearer {token}',
            'X-Api-Version': ExossCardApiVersion
        }
    }

    static get(url, token) {
        const options = {
            url: `${ExossCardApiUrl}${url}`,
            method: 'GET',
            headers: this.getHeaders(token)
        };
        return axios(options);
    }

    static post(url, dados, token, method) {
        const options = {
            url: `${ExossCardApiUrl}${url}`,
            method: method ? method : 'POST',
            headers: this.getHeaders(token),
            data: dados
        };
        return axios(options);
    }

    static getGeral(url, token) {
        const options = {
            url: `${ExossCardApiUrl}/geral/${url}`,
            method: 'GET',
            headers: this.getHeaders(token)
        };
        return axios(options);
    }

    static postGeral(url, dados, token, method) {
        const options = {
            url: `${ExossCardApiUrl}/geral/${url}`,
            method: method ? method : 'POST',
            headers: this.getHeaders(token),
            data: dados
        };
        return axios(options);
    }

    static getEmpresa(url, token) {
        const options = {
            url: `${ExossCardApiUrl}empresa/${url}`,
            method: 'GET',
            headers: this.getHeaders(token)
        };
        return axios(options);
    }

    static postEmpresa(url, dados, token, method) {
        const options = {
            url: `${ExossCardApiUrl}empresa/${url}`,
            method: method ? method : 'POST',
            headers: this.getHeaders(token),
            data: dados
        };
        return axios(options);
    }

    static customRequest(url, metodo, dados, token) {
        const options = {
            url: `${ExossCardApiUrl}${url}`,
            method: metodo,
            headers: this.getHeaders(token),
            data: dados ? dados : {}
        }
        return axios(options);
    }

    static handleErros(response) {
        if (response.status === 400 && response.data.vazio === undefined) {
            return { status: 'badRequest', response: response.data.mensagem }
        } else if (response.status === 401) {
            return { status: 'unauthorized', response: 'Ops, parece que sua sessão expirou, faça um novo login para continuar!' }
        } else if (response.status === 400 && response.data.vazio !== undefined) {
            return { status: "empty" }
        } else {
            return { status: 'serverError', response: 'Ops, ocorreu um erro interno em nosso servidor!' }
        }
    }
}