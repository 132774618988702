import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import SideMenu from '../../../Components/SideMenu';
import Header from '../../../Components/Header';
import Redirect from '../../../Components/RedirectScreen';
import Loading from '../../../Components/LoaderScreen';
import Alert from '../../../Components/Alert';
import Table from '../../../Components/Table';
import Input from '../../../Components/Input';
import ApiService from '../../../Utils/services';
import Funcoes from '../../../Utils/functions';
import Paginacao from '../../../Components/Paginacao';
import { dataBrMask } from '../../../Utils/mask';

export default function CardStatement() {

    const [redirect, setRedirect] = useState(false);
    const [path, setPath] = useState('');
    const [propsToPath, setPropsToPath] = useState();
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [alertType, setAlertType] = useState(2);
    const [filterBy, setFilterBy] = useState('dttransacao');
    const [status, setStatus] = useState('');
    const [dtinicio, setDtInicio] = useState('');
    const [dtfim, setDtFim] = useState('');
    const [dtliberainicio, setDtLiberaInicio] = useState('');
    const [dtliberafim, setDtLiberaFim] = useState('');
    const [transacoes, setTransacoes] = useState(0);
    const [table] = useState({
        campos: [
            { titulo: 'Data', dado: 'data' },
            { titulo: 'Final Cartão', dado: 'cartao' },
            { titulo: 'Valor Bruto / Parcelas', dado: 'valBrutoParcelas', thStyle: { textAlign: 'right' }, tdStyle: { textAlign: 'right' } },
            { titulo: 'Total Líquido', dado: 'totalLiq', thStyle: { textAlign: 'right' }, tdStyle: { textAlign: 'right' } },
            { titulo: 'Status', dado: 'tag', thStyle: { textAlign: 'center' }, tdStyle: { textAlign: 'center' } },
            { titulo: 'Liberação', dado: 'liberacao', thStyle: { textAlign: 'center' }, tdStyle: { textAlign: 'center' } },
            { titulo: 'Ações', dado: 'acoes', thStyle: { textAlign: 'center' }, tdStyle: { textAlign: 'center' } }
        ],
        dados: []
    });
    const [showData, setShowData] = useState(true);
    const [showEmpty, setShowEmpty] = useState(false);

    const status_transacao = ["Não Autorizada", "Aguardando pagamento", "Em análise", "Aprovada", "Disponível", "Em disputa", "Devolvida", "Cancelada"];

    const status_filtro = [
        {
            text: "Selecione",
            value: ""
        },
        {
            text: "Não Autorizada",
            value: 0
        },
        {
            text: "Aguardando pagamento",
            value: 1
        },
        {
            text: "Em análise",
            value: 2
        },
        { 
            text: "Aprovada",
            value: 3
        },
        {
            text: "Disponível",
            value: 4
        },
        {
            text: "Em disputa",
            value: 5
        },
        {
            text: "Devolvida",
            value: 6
        },
        {
            text: "Cancelada",
            value: 7
        }
    ];

    const filtro_por = [
        {
            text: "Data da transação",
            value: 'dttransacao'
        },
        {
            text: "Data da liberação",
            value: "dtliberacao"
        }
    ];

    const user = JSON.parse(sessionStorage.getItem("userData"));

    const [dadosPaginacao, setDadosPaginacao] = useState({
        fist_page: 1,
        last_page: 1,
        current_page: 1,
        per_page: 20
    });

    useEffect(() => {
        const filtros = null;
        if(sessionStorage.getItem("filtro_transacao") !== null){
            const filtros = JSON.parse(sessionStorage.getItem("filtro_transacao"));
            setFilterBy(filtros.filterBy);
            setStatus(filtros.status);
            setDtInicio(filtros.dtinicio);
            setDtFim(filtros.dtfim);
            setDtLiberaInicio(filtros.dtliberainicio);
            setDtLiberaFim(filtros.dtliberafim);
        }
        carregaTransacoes(1);
    }, []);

    const carregaTransacoes = (page, filtro) => {
        setLoading(true);
        var url = 'transacao/transacao?per_page='+dadosPaginacao.per_page+'&page='+page;
        if(sessionStorage.getItem("filtro_transacao") !== null){
            const filtros = JSON.parse(sessionStorage.getItem("filtro_transacao"));
            setFilterBy(filtros.filterBy);
            setStatus(filtros.status);
            setDtInicio(filtros.dtinicio);
            setDtFim(filtros.dtfim);
            setDtLiberaInicio(filtros.dtliberainicio);
            setDtLiberaFim(filtros.dtliberafim);
            if(filtros.status !== '') url = url + '&status=' + filtros.status;
            if(filtros.filterBy == 'dttransacao'){
                if(filtros.dtinicio !== '') url = url + '&dtinicio=' + Funcoes.data2Americano(filtros.dtinicio);
                if(filtros.dtfim !== '') url = url + '&dtfim=' + Funcoes.data2Americano(filtros.dtfim);
            }else{
                if(filtros.dtliberainicio !== '') url = url + '&dtlibera_inicio=' + Funcoes.data2Americano(filtros.dtliberainicio);
                if(filtros.dtliberafim !== '') url = url + '&dtlibera_fim=' + Funcoes.data2Americano(filtros.dtliberafim);
            }
        }else{
            if(status !== '') url = url + '&status=' + status;
            if(filterBy == 'dttransacao'){
                if(dtinicio !== '') url = url + '&dtinicio=' + Funcoes.data2Americano(dtinicio);
                if(dtfim !== '') url = url + '&dtfim=' + Funcoes.data2Americano(dtfim);
            }else{
                if(dtliberainicio !== '') url = url + '&dtlibera_inicio=' + Funcoes.data2Americano(dtliberainicio);
                if(dtliberafim !== '') url = url + '&dtlibera_fim=' + Funcoes.data2Americano(dtliberafim);
            }
        }
        ApiService.get(url+'&tipo=2', user.token)
            .then(result => {
                const response = result.data;
                const dados = [];
                setTransacoes(response.total);
                setDadosPaginacao(response);
                for (var i = 0; i < response.data.length; i++){
                    if(response.data[i].datahora !== null){
                        var data = response.data[i].datahora.split(" ");
                        var hora = data[1].split(":");
                    }
                    dados.push({
                        id: response.data[i].id,
                        data: response.data[i].datahora !== null ? Funcoes.data2Brazilian(data[0]) + ' ' + hora[0] + ':' + hora[1] : '-',
                        cartao: "**** " + response.data[i].final_cartao,
                        valBrutoParcelas: "R$ " + Funcoes.float2Preco(response.data[i].valor) + " / " + response.data[i].parcelas + "x",
                        totalLiq: (response.data[i].status === 3 || response.data[i].status === 4) ? "R$ " + Funcoes.float2Preco(response.data[i].valor_liquido) : "-",
                        saldo: "R$ " + Funcoes.float2Preco(response.data[i].saldo),
                        tag: status_transacao[response.data[i].status],
                        liberacao: (response.data[i].status === 3 || response.data[i].status === 4) ? Funcoes.data2Brazilian(response.data[i].liberacao) : "-",
                        acoes: 'visualizar'
                    });
                }
                table.dados = dados;
                setLoading(false);
            })
            .catch(err => {
                const result = ApiService.handleErros(err.response);
                if (result.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    setPath('/');
                }
                setLoading(false);
                setAlertText(result.response);
                setTimeout(() => {
                    setAlert(true);
                }, 300);
            })
    }

    const handlePage = (page) => {
        dadosPaginacao.page = page;
        carregaTransacoes(page);
    }

    const handleFilter = () => {
        const filtro = JSON.stringify({
            status: status,
            dtinicio: dtinicio,
            dtfim: dtfim,
            dtliberainicio: dtliberainicio,
            dtliberafim: dtliberafim,
            filterBy: filterBy
        });
        console.log(filtro);
        sessionStorage.setItem("filtro_transacao", filtro);
        carregaTransacoes(1, filtro);
    }

    const exportarTransacoes = () => {
        setLoading(true);
        var url = 'transacao/transacao?exportar=true'
        if(status !== '') url = url + '&status=' + status;
        if(filterBy == 'dttransacao'){
            if(dtinicio !== '') url = url + '&dtinicio=' + Funcoes.data2Americano(dtinicio);
            if(dtfim !== '') url = url + '&dtfim=' + Funcoes.data2Americano(dtfim);
        }else{
            if(dtliberainicio !== '') url = url + '&dtlibera_inicio=' + Funcoes.data2Americano(dtliberainicio);
            if(dtliberafim !== '') url = url + '&dtlibera_fim=' + Funcoes.data2Americano(dtliberafim);
        }

        var urlBase = ApiService.ExossCardApiUrl();
        const token = user.token;
        const xhr = new XMLHttpRequest();
        xhr.open("GET", urlBase + url);
        xhr.setRequestHeader("Content-Type", "application/vnd.ms-excel");
        xhr.setRequestHeader("Authorization", "Bearer " + token);
        xhr.responseType = "blob";

        xhr.onload = () => {
            var blob = new Blob([xhr.response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'transacoes.xlsx';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setLoading(false);
        };
        xhr.send();
    }

    const acaoVisualizar = (id) => {
        setPropsToPath({id: id});
        setPath("/adquirencia/detalhes");
        setRedirect(true);
    }

    return (
        <>
            <Redirect redirect={redirect} props={propsToPath} path={path} />
            <Alert show={alert} texto={alertText} tipo={alertType} action={() => { setAlert(false); setRedirect(path ? true : false) }} />
            <Loading show={loading} />
            <SideMenu menuAtivo={4} />

            <div className="body">
                <Header />

                <div className="digitalAccounts mgDefault">
                    <div className="headerBtn">
                        <div>
                            <h1>Extrato de Transações  (cartão)</h1>
                            <p className="descricao">Abaixo a listagem as transações via cartão de crédito.</p>
                        </div>
                        <button className="btn" onClick={() => { exportarTransacoes() }}>Exportar (.xlsx)</button>
                    </div>

                    <div className="panel">
                        <div className="panelBody">
                            <div style={{ display: 'flex' }}>
                                <Input type="select" label="Status da transação" optionsData={status_filtro} onChange={e => { setStatus(e.target.value); }} divStyle={{ maxWidth: 200, marginRight: 34 }} />
                                <div className="rowCenter period">
                                    <Input type="tel" label="Período" value={filterBy == 'dttransacao' ? dtinicio : dtliberainicio } onChange={e => { filterBy == 'dttransacao' ? setDtInicio(dataBrMask(e.target.value)) : setDtLiberaInicio(dataBrMask(e.target.value)) }} divStyle={{ maxWidth: 140 }} />
                                    <p>à</p>
                                    <Input type="tel" label="a" value={filterBy == 'dttransacao' ? dtfim : dtliberafim} onChange={e => { filterBy == 'dttransacao' ? setDtFim(dataBrMask(e.target.value)) : setDtLiberaFim(dataBrMask(e.target.value)) }} divStyle={{ maxWidth: 140 }} />
                                </div>
                                <Input type="select" label="Filtro por" onChange={e => { setDtInicio(''); setDtFim(''); setDtLiberaInicio(''); setDtLiberaFim(''); }} optionsData={filtro_por} divStyle={{ maxWidth: 200, marginLeft: 34 }} />
                                <button className="btn btnFiltrar" onClick={() => { handleFilter() }}>Buscar</button>
                            </div>
                        </div>
                    </div>

                    <div className="panel">
                        {showData ? (<>
                            <Table campos={table.campos}
                                dados={table.dados}
                                acaoVisualizar={acaoVisualizar} />
                        </>) : null}
                    </div>
                    <Paginacao dados={dadosPaginacao} handlePage={handlePage} />
                </div>
            </div>
        </>
    )
}
