import React, { useState, useEffect } from 'react';

import SideMenu from '../../../Components/SideMenu';
import Header from '../../../Components/Header';
import Redirect from '../../../Components/RedirectScreen';
import Loading from '../../../Components/LoaderScreen';
import Alert from '../../../Components/Alert';
import Table from '../../../Components/Table';
import Input from '../../../Components/Input';
import Paginacao from '../../../Components/Paginacao';
import Funcoes from '../../../Utils/functions';
import { cpfMask } from '../../../Utils/mask';
import ApiService from '../../../Utils/services';

export default function DigitalAccount() {

    const [redirect, setRedirect] = useState(false);
    const [path, setPath] = useState('');
    const [propsToPath, setPropsToPath] = useState();
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [alert, setAlert] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [alertType, setAlertType] = useState(2);
    const [user, setUser] = useState({});
    const [contas, setContas] = useState(0);
    const [filterOptions] = useState([
        { value: 'nomerazao', text: 'Nome' },
        { value: 'cpfcnpj', text: 'CPF' },
        { value: 'numero', text: 'Número Conta' },
        { value: 'validada', text: 'Validada' }
    ]);
    const [statusOptions] = useState([
        { value: '', text: 'Selecione'},
        { value: '1', text: 'Sim' },
        { value: '0', text: 'Não' }
    ]);
    const [filterBy, setFilterBy] = useState('nomerazao');
    const [filter, setFilter] = useState('');
    const [table] = useState({
        campos: [
            { titulo: 'Número', dado: 'numero' },
            { titulo: 'Nome', dado: 'nome' },
            { titulo: 'CPF', dado: 'cpf' },
            { titulo: 'Saldo Disp.', dado: 'saldo_disponivel', thStyle: { textAlign: 'right' }, tdStyle: { textAlign: 'right' } },
            { titulo: 'Saldo Bloq.', dado: 'saldo_bloqueado', thStyle: { textAlign: 'right' }, tdStyle: { textAlign: 'right' } },
            { titulo: 'Validada', dado: 'tag', thStyle: { textAlign: 'center' }, tdStyle: { textAlign: 'center' } },
            { titulo: 'Ações', dado: 'acoes', thStyle: { textAlign: 'center' }, tdStyle: { textAlign: 'center' } },
        ],
        dados: []
    });
    const [showData, setShowData] = useState(true);
    const [showEmpty, setShowEmpty] = useState(false);
    const [dadosPaginacao, setDadosPaginacao] = useState({
        fist_page: 1,
        last_page: 1,
        current_page: 1,
        per_page: 20
    });

    useEffect(() => {
        const userData = JSON.parse(sessionStorage.getItem("userData"));
        console.log(JSON.parse(sessionStorage.getItem("filtro_conta")));
        const filtros = null;
        if(sessionStorage.getItem("filtro_conta") !== null){
            const filtros = JSON.parse(sessionStorage.getItem("filtro_conta"));
            setFilterBy(filtros.filterBy);
            setFilter(filtros.filter);
        }
        setUser(userData);
        carregaContas(userData.token, 1, filtros);
    }, []);

    const carregaContas = (token, page, filtro) => {
        setLoading(true);
        var url = 'contadigital/contadigital?per_page='+dadosPaginacao.per_page+'&page='+page;
        if(sessionStorage.getItem("filtro_conta") !== null){
            const filtros = JSON.parse(sessionStorage.getItem("filtro_conta"));
            setFilterBy(filtros.filterBy);
            setFilter(filtros.filter);
            if(filtros.filter !== '') url = url + '&' + filtros.filterBy + '=' + filtros.filter;
        }else{
            if(filter !== '') url = url + '&' + filterBy + '=' + filter;
        }
        ApiService.get(url, token)
            .then(result => {
                const response = result.data;
                const dados = [];
                setContas(response.total);
                setDadosPaginacao(response);
                for (var i = 0; i < response.data.length; i++){
                    dados.push({
                        id: response.data[i].id,
                        numero: response.data[i].numero,
                        nome: response.data[i].nomerazao,
                        cpf: response.data[i].cpfcnpj,
                        saldo_disponivel: 'R$ '+Funcoes.float2Preco(response.data[i].saldo_disponivel),
                        saldo_bloqueado: 'R$ '+Funcoes.float2Preco(response.data[i].saldo_bloqueado),
                        tag: response.data[i].validada ? 'Sim' : 'Não',
                        acoes: 'visualizar|extrato|saldo'
                    });
                }
                table.dados = dados;
                setLoading(false);
            })
            .catch(err => {
                const result = ApiService.handleErros(err.response);
                if (result.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    setPath('/');
                }
                setLoading(false);
                setAlertText(result.response);
                setTimeout(() => {
                    setAlert(true);
                }, 300);
            })
    }

    const handlePage = (page) => {
        dadosPaginacao.page = page;
        carregaContas(user.token, page);
    }

    const handleFilter = () => {
        const filtro = JSON.stringify({
            filter: filter,
            filterBy: filterBy
        });
        sessionStorage.setItem("filtro_conta", filtro);
        carregaContas(user.token, 1);
    }

    const acaoExtrato = (id) => {
        var nome = '';
        for(var i = 0; i < table.dados.length; i++){
            if(table.dados[i].id == id){
                nome = table.dados[i].nome;
            }
        }
        setPropsToPath({id: id, nome: nome});
        setPath("/contas-digitais/extrato");
        setRedirect(true);
    }

    const acaoVisualizar = (id) => {
        var nome = '';
        for(var i = 0; i < table.dados.length; i++){
            if(table.dados[i].id == id){
                nome = table.dados[i].nome;
            }
        }
        setPropsToPath({id: id, nome: nome});
        setPath("/contas-digitais/detalhes");
        setRedirect(true);
    }

    const acaoSaldo = (id) => {
        var nome = '', conta = '';
        for(var i = 0; i < table.dados.length; i++){
            if(table.dados[i].id == id){
                nome = table.dados[i].nome;
                conta = table.dados[i].numero;
            }
        }
        setPropsToPath({id: id, nome: nome, conta: conta, id: id});
        setPath("/contas-digitais/saldo_liberar");
        setRedirect(true);
    }

    return (
        <>
            <Redirect redirect={redirect} props={propsToPath} path={path} />
            <Alert show={alert} texto={alertText} tipo={alertType} action={() => { setAlert(false); setRedirect(path ? true : false) }} />
            <Loading show={loading} />
            <SideMenu menuAtivo={2} />

            <div className="body">
                <Header />

                <div className="digitalAccounts mgDefault">
                    <div className="headerBtn">
                        <div>
                            <h1>Contas Digitais</h1>
                            <p className="descricao">Abaixo a listagem de todas as contas de sua wallet</p>
                        </div>
                    </div>

                    <div className="panel">
                        <div className="panelBody">
                            <div style={{ display: 'flex' }}>
                                <Input type="select" label="Filtrar por" optionsData={filterOptions} value={filterBy}
                                    onChange={e => { setFilterBy(e.target.value); setFilter('') }} divStyle={{ maxWidth: 200, marginRight: 34 }} />
                                {filterBy === "validada" ?
                                    <Input type="select" label="a" optionsData={statusOptions} value={filter} onChange={e => setFilter(e.target.value)}
                                        divStyle={{ maxWidth: 250, marginRight: 34 }} />
                                    :
                                    <Input type="text" label="Digite o que procura" value={filter} divStyle={{ maxWidth: 460 }} maxLength={filterBy === "cpfcnpj" ? 14 : 40}
                                        onChange={e => setFilter(filterBy === "cpfcnpj" ? cpfMask(e.target.value) : e.target.value)} />
                                }
                                <button className="btn btnFiltrar" onClick={() => { handleFilter() }}>Filtrar</button>
                            </div>
                        </div>
                    </div>

                    <p className="totalResults">Total de Contas: {contas}</p>
                    <div className="panel">
                        {showData ? (<>
                            <Table campos={table.campos}
                                dados={table.dados}
                                acaoEditar={acaoExtrato}
                                acaoVisualizar={acaoVisualizar}
                                acaoSaldo={acaoSaldo} />
                        </>) : null}
                    </div>
                    <Paginacao dados={dadosPaginacao} handlePage={handlePage} />
                </div>
            </div>
        </>
    )
}
