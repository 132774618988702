import React from 'react';

export default function Modal({ show, largura, children }) {

    const width = largura ? largura : 320;
    return (
        <>
            {show ? (
                <div style={styles.backdrop}>
                    <div style={{ width, backgroundColor: '#FFF', borderRadius: 5, textAlign: 'center', padding: 21, borderWidth: 1, borderStyle: 'solid', borderColor: '#EEE', maxHeight: '80%', overflow: 'auto' }}>
                        {children}
                    </div>
                </div>
            ) : null
            }
        </>
    );
}

const styles = {
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)', width: '100%', height: '100%', display: 'flex', position: 'fixed',
        justifyContent: 'center', alignItems: 'center', zIndex: 3
    }
};