import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import SideMenu from '../../../Components/SideMenu';
import Header from '../../../Components/Header';
import Redirect from '../../../Components/RedirectScreen';
import Loading from '../../../Components/LoaderScreen';
import Alert from '../../../Components/Alert';
import Table from '../../../Components/Table';
import Input from '../../../Components/Input';
import Funcoes from '../../../Utils/functions';
import { cpfMask } from '../../../Utils/mask';
import ApiService from '../../../Utils/services';

export default function DigitalAccountBalance(props) {

    const [redirect, setRedirect] = useState(false);
    const [path, setPath] = useState('');
    const [propsToPath, setPropsToPath] = useState();
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [alert, setAlert] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [alertType, setAlertType] = useState(2);
    const [user, setUser] = useState({});
    const [nome, setNome] = useState('');
    const [conta, setConta] = useState('');
    const [table] = useState({
        campos: [
            { titulo: 'Valor', dado: 'valor', thStyle: { textAlign: 'right' }, tdStyle: { textAlign: 'right' } },
            { titulo: 'Payment ID', dado: 'paymentid' },
            { titulo: 'Data / Hora Pagto.', dado: 'datahora_pagto' },
            { titulo: 'Data Liberação', dado: 'data_previsao' },
        ],
        dados: []
    });
    const [showEmpty, setShowEmpty] = useState(false);

    useEffect(() => {
        const userData = JSON.parse(sessionStorage.getItem("userData"));
        setUser(userData);
        setNome(props.location.state.nome);
        setConta(props.location.state.conta);
        carregaLista(userData.token, props.location.state.id);
    }, []);

    const carregaLista = (token, id) => {
        setLoading(true);
        ApiService.get('contadigital/saldoaliberar/'+id, token)
            .then(result => {
                const response = result.data;
                const dados = [];
                for (var i = 0; i < response.data.length; i++){
                    dados.push({
                        valor: 'R$ '+Funcoes.float2Preco(response.data[i].valor),
                        paymentid: response.data[i].paymentid,
                        datahora_pagto: Funcoes.dataHora2Brazilian(response.data[i].datahora_pagamento),
                        data_previsao: Funcoes.data2Brazilian(response.data[i].data_previsao)
                    });
                }
                table.dados = dados;
                setLoading(false);
            })
            .catch(err => {
                const result = ApiService.handleErros(err.response);
                if(result.status !== "empty"){
                    if (result.status === "unauthorized") {
                        sessionStorage.removeItem('userData');
                        setPath('/');
                    }
                    setLoading(false);
                    setAlertText(result.response);
                    setTimeout(() => {
                        setAlert(true);
                    }, 300);
                }else{
                    setLoading(false);
                }
            })
    }

    return (
        <>
            <Redirect redirect={redirect} props={propsToPath} path={path} />
            <Alert show={alert} texto={alertText} tipo={alertType} action={() => { setAlert(false); setRedirect(path ? true : false) }} />
            <Loading show={loading} />
            <SideMenu menuAtivo={2} />

            <div className="body">
                <Header />

                <div className="digitalAccounts mgDefault">
                    <div className="headerBtn">
                        <div>
                            <h1>Saldo à liberar</h1>
                            <p className="descricao">Conta: {conta} ({nome})</p>
                        </div>
                    </div>

                    <div className="panel">
                        <Table campos={table.campos}
                            dados={table.dados} />
                    </div>

                    <Link to="/contas-digitais" className="btn btnVoltar">{'<'} Voltar</Link>
                </div>
            </div>
        </>
    )
}
