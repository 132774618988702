import React, { Component, Fragment } from 'react';

export default class Paginacao extends Component {

    renderBotoes = () => {
        const { dados, handlePage } = this.props;
        if (dados.last_page > 1) {
            var objects = [];
            for (var i = 1; i <= dados.last_page; i++) {
                objects[i] = { active: i === dados.current_page ? true : false, index: i };
            }
            return objects.map((page) => {
                return (
                    <button className={page.active ? "active" : ""} onClick={() => handlePage(page.index)}>{page.index}</button>
                )
            })
        }
    }

    render() {
        const { dados, style, handlePage } = this.props;
        var pagAtual = dados.current_page;
        var ultPag = dados.last_page;
        return (
            <Fragment>
                {dados.last_page > 1 ? (
                    <div className="paginacao" style={style}>
                        <button className={pagAtual === 1 ? "disabled" : ''} disabled={pagAtual === 1 ? true : false}
                            onClick={() => handlePage(pagAtual - 1)}>{'<'}</button>
                        {this.renderBotoes()}
                        <button className={pagAtual === ultPag ? "disabled" : ''} disabled={pagAtual === ultPag ? true : false}
                            onClick={() => handlePage(pagAtual + 1)}>></button>
                    </div>
                ) : null}
            </Fragment>
        );
    }
}