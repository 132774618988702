import React, { Component, Fragment } from 'react';

import iconSair from '../resources/img/icon-sair.svg';

import ApiService from '../Utils/services';

import { Link } from 'react-router-dom';

export default class Header extends Component {

    async realizaLogout() {
        var userData = sessionStorage.getItem('userData');
        if (userData) {
            let user = JSON.parse(userData);
            await ApiService.postEmpresa('logout', {}, user.token)
            sessionStorage.removeItem('userData')
        }
    }

    render() {
        return (
            <Fragment>
                <div className="navbar">
                    <div className="barLinks">
                        <ul>
                            <li>
                                <Link to="/">Sair <img src={iconSair} alt="Ícone de logout" /></Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </Fragment>
        )
    }
}