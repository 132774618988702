import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import SideMenu from '../../../Components/SideMenu';
import Header from '../../../Components/Header';
import Redirect from '../../../Components/RedirectScreen';
import Loading from '../../../Components/LoaderScreen';
import Alert from '../../../Components/Alert';
import Table from '../../../Components/Table';
import Input from '../../../Components/Input';
import ApiService from '../../../Utils/services';
import Funcoes from '../../../Utils/functions';
import Paginacao from '../../../Components/Paginacao';
import { dataBrMask } from '../../../Utils/mask';

export default function DigitalAccountStatement(props) {

    const operacoes = [
        {
            "value": "",
            "text": "Selecione"
        },
        {
            "value": 1,
            "text": "Crédito"
        },
        {
            "value": 2,
            "text": "Débito"
        }
    ];

    const [redirect, setRedirect] = useState(false);
    const [path, setPath] = useState('');
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [alertType, setAlertType] = useState(2);
    const [table] = useState({
        campos: [
            { titulo: 'Data/Hora', dado: 'dtHr' },
            { titulo: 'Descrição/Histórico', dado: 'descricao' },
            { titulo: 'Operação', dado: 'operacao', thStyle: { textAlign: 'center' }, tdStyle: { textAlign: 'center' } },
            { titulo: 'Valor', dado: 'valor', thStyle: { textAlign: 'right' }, tdStyle: { textAlign: 'right' } },
            { titulo: 'Saldo', dado: 'saldo', thStyle: { textAlign: 'right' }, tdStyle: { textAlign: 'right' } },
        ],
        dados: []
    });
    const [showData, setShowData] = useState(true);
    const [showEmpty, setShowEmpty] = useState(false);
    const [idConta, setIdConta] = useState('');
    const [operacao, setOperacao] = useState('');
    const [dtinicio, setDtinicio] = useState('');
    const [dtfim, setDtfim] = useState('');
    const [user, setUser] = useState({});
    const [nome, setNome] = useState('');
    const [registros, setRegistros] = useState(0);
    const [dadosPaginacao, setDadosPaginacao] = useState({
        fist_page: 1,
        last_page: 1,
        current_page: 1,
        per_page: 20
    });

    useEffect(() => {
        const id = props.location.state.id;
        const userData = JSON.parse(sessionStorage.getItem("userData"));
        setUser(userData);
        setNome(props.location.state.nome);
        setIdConta(id);
        carregaExtrato(userData.token, id, 1);
    }, []);

    const carregaExtrato = (token, id, page) => {
        setLoading(true);
        var url = 'contadigital/extrato/' + id + '?per_page='+dadosPaginacao.per_page+'&page='+page;
        if(operacao !== '') url = url + '&operacao=' + operacao;
        if(dtinicio !== '') url = url + '&dtinicio=' + Funcoes.data2Americano(dtinicio);
        if(dtfim !== '') url = url + '&dtfim=' + Funcoes.data2Americano(dtfim);
        ApiService.get(url, token)
            .then(result => {
                const response = result.data;
                const dados = [];
                setRegistros(response.total);
                setDadosPaginacao(response);
                for (var i = 0; i < response.data.length; i++){
                    var dt = Funcoes.data2Brazilian(response.data[i].datahora.split(" ")[0]);
                    var hr = response.data[i].datahora.split(" ");
                    hr = hr[1];
                    console.log(hr.length);
                    hr = hr.substring(0, hr.length - 3);
                    dados.push({
                        id: response.data[i].id,
                        dtHr: dt + ' ' + hr,
                        descricao: response.data[i].descricao,
                        operacao: (response.data[i].operacao == 1 ? 'C' : 'D'),
                        valor: ((response.data[i].operacao == 1 ? '' : '-') + 'R$ ' + Funcoes.float2Preco(response.data[i].valor)),
                        saldo: 'R$ ' + Funcoes.float2Preco(response.data[i].saldo)
                    });
                }
                table.dados = dados;
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
                const result = ApiService.handleErros(err.response);
                if (result.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    setPath('/');
                }
                setLoading(false);
                setAlertText(result.response);
                setTimeout(() => {
                    setAlert(true);
                }, 300);
            })
    }

    const exportarExtrato = () => {
        setLoading(true);
        var url = 'contadigital/extrato/' + props.location.state.id + '?exportar=true'
        if(operacao !== '') url = url + '&operacao=' + operacao;
        if(dtinicio !== '') url = url + '&dtinicio=' + Funcoes.data2Americano(dtinicio);
        if(dtfim !== '') url = url + '&dtfim=' + Funcoes.data2Americano(dtfim);

        var urlBase = ApiService.ExossCardApiUrl();
        const token = user.token;
        const xhr = new XMLHttpRequest();
        xhr.open("GET", urlBase + url);
        xhr.setRequestHeader("Content-Type", "application/vnd.ms-excel");
        xhr.setRequestHeader("Authorization", "Bearer " + token);
        xhr.responseType = "blob";

        xhr.onload = () => {
            var blob = new Blob([xhr.response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'extrato.xlsx';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setLoading(false);
        };
        xhr.send();
    }

    const handlePage = (page) => {
        dadosPaginacao.page = page;
        carregaExtrato(user.token, idConta, page);
    }

    const handleFilter = () => {
        carregaExtrato(user.token, idConta, 1);
    }

    return (
        <>
            <Redirect redirect={redirect} path={path} />
            <Alert show={alert} texto={alertText} tipo={alertType} action={() => { setAlert(false); setRedirect(path ? true : false) }} />
            <Loading show={loading} />
            <SideMenu menuAtivo={2} />

            <div className="body">
                <Header />

                <div className="digitalAccounts mgDefault">
                    <div className="headerBtn">
                        <div>
                            <h1>Extrato Conta - {nome}</h1>
                            <p className="descricao">Abaixo os lançamentos do extrato da conta.</p>
                        </div>
                        <button className="btn" onClick={() => { exportarExtrato() }}>Exportar (.xlsx)</button>
                    </div>

                    <div className="panel">
                        <div className="panelBody">
                            <div style={{ display: 'flex' }}>
                                <Input type="select" label="Opereção" onChange={e => { setOperacao(e.target.value); }} optionsData={operacoes} divStyle={{ maxWidth: 200, marginRight: 34 }} />
                                <div className="rowCenter period">
                                    <Input type="tel" label="Período" value={dtinicio} maxLength="10" onChange={e => { setDtinicio(dataBrMask(e.target.value))} } divStyle={{ maxWidth: 140 }} />
                                    <p>à</p>
                                    <Input type="tel" label="a" value={dtfim} maxLength="10" onChange={e => { setDtfim(dataBrMask(e.target.value)) }} divStyle={{ maxWidth: 140 }} />
                                </div>

                                <button className="btn btnFiltrar" onClick={() => { handleFilter() }}>Buscar</button>
                            </div>
                        </div>
                    </div>

                    <div className="panel">
                        {showData ? (<>
                            <Table campos={table.campos}
                                dados={table.dados}
                                acaoEditar={() => { setPath('/contas-digitais/extrato'); setRedirect(true); }}
                                acaoVisualizar={() => { setPath('/contas-digitais/detalhes'); setRedirect(true); }} />
                        </>) : null}
                    </div>

                    <div class="footerWithPag">

                        <div class="footerActions">
                            <Link to="/contas-digitais" className="btn btnVoltar">{'<'} Voltar</Link>
                        </div>

                        <Paginacao dados={dadosPaginacao} handlePage={handlePage} />
                    </div>
                </div>
            </div>
        </>
    )
}
